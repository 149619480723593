
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

/* For use in src/lib/core/theming/_palette.scss */
$md-green: (
    50 : #e4f6e9,
    100 : #bae9c8,
    200 : #8ddba3,
    300 : #5fcc7e,
    400 : #3cc163,
    500 : #1ab647,
    600 : #17af40,
    700 : #13a637,
    800 : #0f9e2f,
    900 : #088e20,
    A100 : #bcffc5,
    A200 : #89ff99,
    A400 : #56ff6c,
    A700 : #3cff56,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-mcgpale: (
    50 : #f6e2ed,
    100 : #e7b6d2,
    200 : #d885b5,
    300 : #c85497,
    400 : #bc3080,
    500 : #b00b6a,
    600 : #a90a62,
    700 : #a00857,
    800 : #97064d,
    900 : #87033c,
    A100 : #ffb3cf,
    A200 : #ff80ae,
    A400 : #ff4d8e,
    A700 : #ff347e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);




// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$sendsii-primary: mat.define-palette($md-green);
$sendsii-accent: mat.define-palette($md-mcgpale, 500, A100, A400);

// The warn palette is optional (defaults to red).
$sendsii-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$sendsii-theme: mat.define-light-theme((
  color: (
    primary: $sendsii-primary,
    accent: $sendsii-accent,
    warn: $sendsii-warn,
  )
));
/* For use in src/lib/core/theming/_palette.scss */


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($sendsii-theme);

