/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=GFS+Neohellenic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Nunito&display=swap');


html, body { height: 100%; }

body {
    font-family: 'Montserrat', sans-serif !important;
    margin: 0; 
    color: #76787a !important;
   
  }
  p , a {
    font-family: 'Nunito', sans-serif !important;
  }

  .mat-typography, .mat-typography h1 , .mat-typography h2   {
    font-family: 'Montserrat', sans-serif !important;
  
     }
h2 {
  font-family: 24px !important;
}
   
  .mat-typography h1 {
    font-weight: 700 !important;
   
  }

  .mat-typography h2   {
   
    font-family: 24px !important;
   
  }


.titleColor {
    color: #0cb13c;
}


.pColor {
  color: #0cb13c;
}

.sColor {
  color: #b10a6b;
}

.bg-light-Grey {
  background-color: #f1f1f182;
}
.bg-primary2 {
  background-color: #0cb13c !important;
}

.bg-lightGrey {
   background-color: #f4fafd;
}

h1.heading-md {
    color: #121127;
    line-height: normal;
    font-weight: 700 !important;  
   
    font-size: 36px !important;
}

h1.heading-lg {
    color: #121127;
    line-height: normal;
    font-weight: 700 !important;
    margin-bottom: 40px !important;
    font-size: 65px !important;
}


@media (min-width: 1200px)  {

h2, h2 {
    font-size: 24px !important;
}
}

.fa-circle-info:before, .fa-info-circle:before {
  content: "\f05a";
  color: cadetblue !important;
}


.activeTab {
  position: relative;   
}


.activeTab:after {
   position: absolute;
   background: #b10a6b;
   content: "";
   display: block;
   height: 3px;
   top: 38px;
   left: 10px;
   right: 0;
   bottom: 0;
   width: 80%;
  border-radius: 10%;
}


@media all and (max-width: 680px) {

  .activeTab:after {
    position: absolute;
    background: #b10a6b;
    content: "";
    display: block;
    height: 3px;
    top: 38px;
    left: 9px;
    right: 0;
    bottom: 0;
    width: 40%;
    border-radius: 10%;
  }
}

.bg-breadCrumb {
  background-image: url(assets/images/breadcrumb-2.svg);
  background-repeat: no-repeat;
  background-size: cover;
  
}

.fs12 {
  font-size: 12px;
}

.clip-text_one {
  background-image: url('assets/images/Mask group.png');
  background-size: cover;
  animation: anim 5s infinite ease-in;
  animation-duration: 10s;
  animation-delay: 1s;
}

.clip-text_revert {
  background-image: url('assets/images/revert-mask-group.png');
  background-size: cover;
  animation: anim 10s infinite ease-in;
  animation-duration: 10s;
  animation-delay: 5s;
}